<template>
  <div>
    <div class="cursor-pointer" @click.stop="showDialog()">
      {{ buttonLabel }}
    </div>
    <v-dialog v-model="dialog" persistent max-width="400" scrollable>
      <v-card>
        <v-card-title class="title text-capitalize">
          {{ buttonLabel | badgeOwnership }}
        </v-card-title>
        <v-card-text>
          <user-badge-form
            ref="userBadgeForm"
            :key="componentKey"
            :items="form.userBadges"
          >
          </user-badge-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" color="error">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const badgesRepository = RepositoryFactory.get("badge");

const UserBadgeForm = () => import("../forms/UserBadgeForm.vue");

export default {
  components: {
    UserBadgeForm
  },
  filters: {
    badgeOwnership(value) {
      if (value) {
        return `${value}'s Badges`;
      }

      return value;
    }
  },
  data: () => ({
    dialog: false,
    componentKey: 0,
    form: {},
    loading: false
  }),
  props: {
    buttonLabel: {
      type: String,
      required: true
    },
    badges: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    async getItem() {
      let userBadges = this.badges || [];
      let categories = badgesRepository.getCategories();

      let items = [];

      categories.map(category => {
        let filterUserBadges = userBadges
          .filter(userBadge => userBadge.category === category.value)
          .map(filteredUserBadge => {
            return {
              image_url: filteredUserBadge.image_url,
              description: filteredUserBadge.description,
              date_acquired: filteredUserBadge.pivot.created_at,
              category: filteredUserBadge.category
            };
          });

        if (filterUserBadges && filterUserBadges.length > 0) {
          items.push(category);
          items[items.length - 1].items = [...filterUserBadges];
        }
      });

      this.form = {
        userBadges: items
      };
    },
    async showDialog() {
      await this.getItem();

      this.dialog = await true;
    },
    closeDialog() {
      this.componentKey++;
      this.dialog = false;
    }
  }
};
</script>
